<template>
    <div class="file d-flex flex-column">
        <div class="ms-auto">
            <div class="position-absolute tag">
                <div
                    class="position-relative badge rounded-pill bg-success"
                    v-if="file.file.attachedFiles"
                >
                    <i class="far fa-file-pdf"></i>
                </div>
                <div class="position-relative badge rounded-pill bg-info" v-else>
                    <i class="far fa-cloud-upload"></i>
                </div>
            </div>
        </div>
        <div class="w-100 p-2 g-0 text-center">
            <div>
                <img
                    v-if="file.file.mimeType === 'image/jpeg' || file.file.mimeType === 'image/png'"
                    :src="`${url}${file.file.id}`"
                    alt="Imagen"
                    class="img-fluid"
                    :class="{
                        'media-icon':
                            file.file.mimeType !== 'image/jpeg' &&
                            file.file.mimeType !== 'image/png',
                    }"
                />

                <div class="w-100" v-else style="background-color: #ccc" @click="openModal">
                    <vue-pdf-embed
                        class="vue-pdf-embed"
                        :disableTextLayer="false"
                        :source="url + file.file.id"
                        :page="1"
                        ref="pdfPreview"
                    />
                </div>
            </div>
        </div>
        <div class="row g-0 p-2 mt-auto">
            <div class="col-md-8 mb-2">{{ file.order }}. {{ file.file.title }}</div>
            <div class="d-flex justify-content-end col-md-4 pe-2 mb-2">
                <div class="mt-auto">
                    <input
                        type="checkbox"
                        class="mx-1 form-check-input"
                        v-model="model"
                        @change="$emit('onChange')"
                        :value="file.file._id"
                        v-if="!readOnly && selectable"
                    />
                    <i class="mx-1 far fa-eye" @click="openModal"></i
                    ><i
                        role="button"
                        class="mx-1 far fa-download"
                        @click="handleDownloadDocument()"
                    ></i>
                    <i
                        v-if="!readOnly && removable"
                        class="mx-1 far fa-trash-alt"
                        @click="$emit('onRemove', file.file._id)"
                    ></i>
                </div>
            </div>
        </div>
        <DocumentViewerModal
            v-if="file.file.mimeType === 'application/pdf'"
            :modal-id="modalId"
            :url="url + file.file.id"
            :title="file.file.title"
            @onClose="closenModal"
        />
        <ImageViewerModal
            v-if="file.file.mimeType === 'image/jpeg' || file.file.mimeType === 'image/png'"
            :modal-id="modalId"
            :title="file.file.title"
            :url="url + file.file.id"
            @onClose="closenModal"
        />
    </div>
</template>
<script>
import { computed, ref, watch } from 'vue';
import { useVfm } from 'vue-final-modal';
import VuePdfEmbed from 'vue-pdf-embed';
import useResize from '@/services/useResize';
import useDownload from '@/services/useDownload';
import DocumentViewerModal from './DocumentViewerModal.vue';
import ImageViewerModal from './ImageViewerModal.vue';

export default {
    name: 'ResultadoFile',
    components: { DocumentViewerModal, VuePdfEmbed, ImageViewerModal },
    props: {
        modelValue: [Boolean, Array],
        file: Object,
        readOnly: { type: Boolean, default: false },
        selectable: { type: Boolean, default: false },
        removable: { type: Boolean, default: true },
    },
    setup(props, { emit }) {
        const url = `${process.env.VUE_APP_STATIC}/resultado/document/${props.file.file.id}`;
        const pdfPreview = ref();
        const { width } = useResize();
        const { downloadPdf } = useDownload();
        const vfm = useVfm();
        const modalId = Symbol(props.file.file.id);
        const model = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                emit('update:modelValue', value);
            },
        });

        const openModal = () => {
            vfm.open(modalId);
        };

        const closenModal = () => {
            vfm.close(modalId);
        };

        const handleDownloadDocument = () => {
            downloadPdf(url, props.file.file.title, props.file.file.mimeType);
        };

        watch(
            () => width.value,
            () => {
                if (pdfPreview.value) pdfPreview.value.render();
                // eslint-disable-next-line comma-dangle
            }
        );

        return {
            model,
            modalId,
            openModal,
            closenModal,
            pdfPreview,
            handleDownloadDocument,
            url: `${process.env.VUE_APP_MAIN_SERVICE}/care/examen/resultado/files/view/`,
        };
    },
};
</script>
<style lang="scss" scoped>
.file {
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
    height: 100%;
}
.file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}

.tag {
    right: 5%;
    z-index: 1;
}
</style>
