import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const doctor = ref(null);
    const doctores = ref([]);

    const getDoctor = async (id) => {
        const res = await axios.get(`/care/doctor/${id}`);

        if (res.status === 200) {
            doctor.value = res.data;
        }

        return res.data;
    };

    const getDoctores = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/doctor${query}`);

        if (res.status === 200) {
            doctores.value = res.data.items;
        }
    };

    const createDoctor = async (data) => {
        const res = await axios.post('/care/doctor', data);
        if (res.status === 201) {
            // console.log(res.data);
            doctor.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateDoctor = async (id, data) => {
        const res = await axios.put(`/care/doctor/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            doctor.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    // eslint-disable-next-line object-curly-newline
    return { doctor, doctores, getDoctor, getDoctores, createDoctor, updateDoctor };
}
