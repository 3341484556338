<template>
    <vue-final-modal :modal-id="modalId" classes="modal-container" content-class="modal-content">
        <div
            class="modal show d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Publicar Documentos</h1>
                        <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            @click="vfm.close(modalId)"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <p>Se enviaran los siguientes documentos al correo del paciente:</p>
                        <ul v-if="files?.length">
                            <li v-for="f in files" :key="f._id">
                                {{ f.file.title }}
                            </li>
                        </ul>
                        <p v-else class="text-danger">No Hay Documentos Seleccionados</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-warning" @click="vfm.close(modalId)">
                            Close
                        </button>
                        <button type="button" class="btn btn-primary" @click="$emit('onConfirm')">
                            Confirmar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script>
import { VueFinalModal, useVfm } from 'vue-final-modal';

export default {
    name: 'FinalConfirmModal',
    components: {
        VueFinalModal,
    },
    props: {
        modalId: [String, Symbol],
        files: Array,
    },
    setup() {
        const vfm = useVfm();

        return { vfm };
    },
};
</script>
<style lang="scss" scoped></style>
