import axios from '@/services/useAxios';

export default function (urlSegment) {
    // console.log(urlSegment);
    const url = `${process.env.VUE_APP_MAIN_SERVICE}${urlSegment}`;

    const saveFile = async (file) => {
        const formData = new FormData(); // pass data as a form
        formData.append('file', file);

        /**
         * post the formData to your backend where storage is processed.
         * In the backend, you will need to loop through the array and
         * save each file through the loop.
         */
        const res = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data;
    };

    return { saveFile };
}
