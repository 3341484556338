<template>
    <VueFinalModal>
        <div class="modal show d-block">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Ingresar Paciente</h5>
                        <button type="button" class="btn-close" @click="$emit('onClose')"></button>
                    </div>
                    <div class="modal-body theme-form">
                        <div class="mb-3">
                            <label for="nombre" class="form-label">Nombre</label>
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="nombre"
                                v-model="state.name"
                                :class="{ 'is-invalid': v.name.$error }"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="apellidos" class="form-label">Apellidos</label>
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="apellidos"
                                v-model="state.surnames"
                                :class="{ 'is-invalid': v.surnames.$error }"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="tipo_identificacion" class="form-label"
                                >Tipo de Identificacion</label
                            >
                            <select
                                class="form-select btn-square digits"
                                id="tipo_identificacion"
                                v-model="state.identification_type"
                                :class="{ 'is-invalid': v.identification_type.$error }"
                            >
                                <option disable=""></option>
                                <option value="CC">Cedula</option>
                                <option value="CE">Cédula de extranjería</option>
                                <option value="TI">Tarjeta de Identidad</option>
                                <option value="RC">Registro civil de nacimiento</option>
                                <option value="PP">Pasaporte</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="identificacion" class="form-label"># Indentifcaion</label>
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="identificacion"
                                v-model="state.identification"
                                :class="{ 'is-invalid': v.identification.$error }"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="contacto" class="form-label"># de Contacto</label>
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="contacto"
                                v-model="state.contact[0]"
                                :class="{ 'is-invalid': v.contact.$error }"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="correo" class="form-label">Correo</label>
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="correo"
                                v-model="state.email"
                                :class="{ 'is-invalid': v.email.$error }"
                            />
                        </div>
                        <div class="mb-3 row">
                            <label class="col-sm-3 col-form-label">Entidad</label>
                            <div class="col-sm-9">
                                <Autocomplete
                                    :debounce="1200"
                                    @onInput="getInstituciones"
                                    :items="instituciones"
                                    :displayItem="
                                        (item) => (typeof item === 'string' ? item : item.name)
                                    "
                                    placeholder="Buscar una entidad"
                                    @onSelect="onSelectEps"
                                    ref="epsAutocomplete"
                                    :class="{ 'is-invalid': v.eps.$error }"
                                ></Autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-end">
                        <button class="btn btn-sm btn-outline-danger" @click="$emit('onClose')">
                            Cancelar
                        </button>
                        <button class="btn btn-sm btn-primary ms-3" @click="handlerCreatePaciente">
                            Ingresar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
<script>
import { computed, reactive, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useSnackbar } from 'vue3-snackbar';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import Autocomplete from '@/components/form/Autocomplete.vue';

import usePaciente from './services/usePaciente';
import useInstituciones from '../Instituciones/services/useInstituciones';

export default {
    name: 'PacienteModalForm',
    components: { VueFinalModal, Autocomplete },
    setup(props, { emit }) {
        const snackbar = useSnackbar();
        const { paciente, createPaciente } = usePaciente();
        const { instituciones, getInstituciones } = useInstituciones();

        const state = reactive({
            id: '',
            consecutive: '',
            name: '',
            surnames: '',
            identification_type: '',
            identification: '',
            email: '',
            contact: [''],
            eps: '',
            status: 1,
        });

        // eslint-disable-next-line no-unused-vars
        const resetWindow = () => {
            state.id = '';
            state.consecutive = '';
            state.name = '';
            state.surnames = '';
            state.identification_type = '';
            state.identification = '';
            state.email = '';
            state.contact = [''];
            state.eps = '';
            state.status = '';
        };

        /** ***** Autocompleters ***** */
        const epsAutocomplete = ref(); // autocomplete input
        const epsSelected = ref(null);
        const onSelectEps = (eps) => {
            state.eps = eps.id;
            epsAutocomplete.value.setText(eps.name);
            epsSelected.value = eps;
        };

        /** ***** Validator ***** */
        const rules = computed(() => ({
            name: { required },
            surnames: { required },
            identification_type: { required },
            identification: { required },
            eps: { required },
            email: { email },
            contact: { required },
            status: { required },
        }));
        const v = useVuelidate(rules, state, { $stopPropagation: true });

        const handlerCreatePaciente = async () => {
            await v.value.$validate();
            if (!v.value.$error) {
                try {
                    await createPaciente({
                        name: state.name,
                        surnames: state.surnames,
                        identification: state.identification,
                        identification_type: state.identification_type,
                        email: state.email,
                        contact: state.contact,
                        eps: state.eps,
                        status: state.status,
                    });
                    snackbar.add({
                        type: 'success',
                        text: 'Paciente ingresado',
                    });

                    emit('onConfirm', { ...paciente.value });
                } catch (error) {
                    snackbar.add({
                        type: 'warning',
                        text: 'Error al ingresar el paciente',
                    });
                }
            }
        };

        return {
            state,
            v,
            handlerCreatePaciente /** Autocompleters */,
            instituciones,
            getInstituciones,
            epsAutocomplete,
            epsSelected,
            onSelectEps,
        };
    },
};
</script>
<style lang="scss" scoped></style>
