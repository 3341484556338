import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useConsulta() {
    const consulta = ref(null);
    const consultas = ref([]);
    const count = ref(0);

    const createConsulta = async (data) => {
        const res = await axios.post('/care/consultas/', data);
        if (res.status === 201) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };
    const createConsultaWithCita = async (data) => {
        const res = await axios.post('/care/consultas/cita', data);
        if (res.status === 201) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getConsulta = async (id) => {
        const res = await axios.get(`/care/consultas/${id}`);
        if (res.status === 200) {
            console.log('getConsulta', res.data);
            consulta.value = res.data;
            return consulta.value;
        }

        throw new Error(res.data.errors);
    };

    const updateConsulta = async (id, data) => {
        const res = await axios.put(`/care/consultas/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
            return consultas.value;
        }

        throw new Error(res.data.errors);
    };

    const closeConsulta = async (id) => {
        const res = await axios.put(`/care/consultas/close/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const buildDocuments = async (id) => {
        const res = await axios.put(`/care/consultas/build/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const renderDocument = async (id, field) => {
        const res = await axios.put(`/care/consultas/render/${id}/${field}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const renderTemplate = async (id, templateId) => {
        const res = await axios.put(`/care/consultas/render/${id}/template/${templateId}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const renderEvolucion = async (id, index) => {
        const res = await axios.put(`/care/consultas/render/${id}/evolucion`, { index });
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeRender = async (id, renderId) => {
        const res = await axios.delete(`/care/consultas/${id}/render/${renderId}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const queryConsultas = async () => {};

    const queryConsultasByPaciente = async (pacienteId, limit = 30, page = 1) => {
        const res = await axios.get(
            // eslint-disable-next-line comma-dangle
            `/care/consultas/paciente/${pacienteId}?limit=${limit}&page=${page}`
        );
        if (res.status === 200) {
            // console.log(res.data);
            consultas.value = res.data.items;
            count.value = res.data.count;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const publishConsulta = async (id) => {
        const res = await axios.put(`/care/consultas/publish/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const resendDocument = async (id, email) => {
        const body = email ? { email } : {};
        const res = await axios.put(`/care/consultas/resend/${id}`, body);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const gobackDocument = async (id) => {
        const res = await axios.put(`/care/consultas/goback/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            consulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        consulta,
        consultas,
        count,
        createConsulta,
        createConsultaWithCita,
        getConsulta,
        updateConsulta,
        closeConsulta,
        buildDocuments,
        removeRender,
        renderDocument,
        renderEvolucion,
        renderTemplate,
        queryConsultas,
        queryConsultasByPaciente,
        publishConsulta,
        resendDocument,
        gobackDocument,
    };
}
