<template>
    <div class="darmin card">
        <div
            class="card-header pb-0 d-flex align-items-center justify-content-between collapsed"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            aria-expanded="true"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Reenviar</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse" :id="`card-${ramId}`">
                <div class="row">
                    <div class="col">
                        <div class="mb-3 row">
                            <label class="col-md-3 col-form-label">Correo</label>
                            <div class="col-md-9">
                                <input class="form-control" type="text" v-model="state.email" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-end">
                        <div class="btn btn-success mb-1" @click="handleSendEmail">Enviar</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, reactive, ref, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { email } from '@vuelidate/validators';

import { useSnackbar } from 'vue3-snackbar';

export default defineComponent({
    name: 'ResendEmail',
    setup(props, { emit }) {
        const snackbar = useSnackbar();
        const ramId = ref(Math.floor(Math.random() * 1000));
        const state = reactive({ email: '' });

        /** ***** Validator ***** */
        const rules = computed(() => ({
            email: { email },
        }));

        const $v = useVuelidate(rules, state);

        const handleSendEmail = async () => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                emit('onSendEmail', state.email);
                state.email = '';
            } else {
                snackbar.add({
                    type: 'error',
                    text: 'Verifique la direccion de correo',
                });
            }
        };

        return { ramId, state, handleSendEmail };
    },
});
</script>

<style>
</style>
