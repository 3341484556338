<template>
    <div class="card-body theme-form" v-if="doctor">
        <div class="row mb-3">
            <div class="col">
                <p class="mb-0">Especialista:</p>
                <p class="mb-0 fs-4 fw-bolder">
                    {{ doctor.fullName }}
                    <i
                        role="button"
                        class="ms-2 edit-link fa fa-edit"
                        v-if="!isReadOnly"
                        @click="handleEdit"
                    ></i>
                </p>
                <div class="d-flex flex-wrap">
                    <p class="pe-3 mb-0" v-if="doctor.specialty?.name">
                        <i class="fa fa-credit-card"></i> {{ doctor.specialty?.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body theme-form" v-else>
        <div class="row">
            <div class="col">
                <div class="row" :class="{ 'is-invalid': v.doctor.$errors.length }">
                    <label class="col-md-3 col-form-label">Especialista</label>
                    <div class="col-md-9" :class="{ 'is-invalid': v.doctor.$errors.length }">
                        <Autocomplete
                            :class="{ 'is-invalid': v.doctor.$errors.length }"
                            :debounce="1200"
                            @onInput="getDoctores"
                            :items="doctores"
                            :displayItem="
                                (item) => (typeof item === 'string' ? item : item.fullName)
                            "
                            placeholder="Buscar un especialista"
                            @onSelect="onSelectDoctor"
                            ref="doctorAutocomplete"
                        ></Autocomplete>
                    </div>
                    <div class="invalid-feedback" v-if="v.doctor.$errors.length">
                        {{ v.doctor.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, onBeforeMount, watch } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import useDoctor from '@/modules/Care/Doctores/services/useDoctor';

export default {
    name: 'DoctorInfo',
    components: { Autocomplete },
    props: {
        modelValue: String,
        isReadOnly: Boolean,
    },
    setup(props, { emit }) {
        // eslint-disable-next-line object-curly-newline, operator-linebreak
        const { doctores, getDoctores, getDoctor, doctor, getNacimientoFormated, getEdad } =
            useDoctor();

        /** ***** Autocompleters ***** */
        const doctorAutocomplete = ref(); // autocomplete input
        const onSelectDoctor = (selected) => {
            emit('update:modelValue', selected.id);
            doctorAutocomplete.value.setText(selected.selectName);
            doctor.value = selected;
            doctores.value = [];
            emit('onChange', selected);
        };

        /** ***** Validator ***** */
        const rules = computed(() => ({
            doctor: { required },
        }));

        const state = computed(() => ({ doctor: props.modelValue }));

        const v = useVuelidate(rules, state);

        onBeforeMount(() => {
            if (props.modelValue) {
                getDoctor(props.modelValue);
            }
        });

        watch(
            () => props.modelValue,
            (newVal) => {
                if (newVal) {
                    if (doctor.value) {
                        if (newVal && newVal !== doctor.value.id) {
                            getDoctor(props.modelValue);
                        }
                    } else if (newVal) {
                        getDoctor(props.modelValue);
                    }
                } else {
                    doctor.value = null;
                }
                // eslint-disable-next-line comma-dangle
            }
        );

        const handleEdit = () => {
            emit('update:modelValue', '');
            emit('onChange', null);
            doctor.value = null;
        };

        return {
            doctores,
            getDoctores,
            onSelectDoctor,
            doctorAutocomplete,
            doctor,
            getNacimientoFormated,
            getEdad,
            handleEdit,
            v,
        };
    },
};
</script>
<style scoped>
.edit-link {
    font-size: 0.5em;
}

.collapsed i {
    transform: rotate(180deg);
}
</style>
