import { ref } from 'vue';
import axios from '@/services/useAxios';
import { DateTime } from 'luxon';

export default function () {
    const paciente = ref(null);
    const pacientes = ref([]);

    const getPaciente = async (id) => {
        const res = await axios.get(`/care/paciente/${id}`);

        if (res.status === 200) {
            paciente.value = res.data;
        }
    };

    const getPacientes = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/paciente${query}`);

        if (res.status === 200) {
            pacientes.value = res.data.items;
        }
    };

    const getPacientesSelect = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
        pacientes.value.map((i) => ({
            id: `${i.id}`,
            fullName: `${i.name}`,
        }));

    const createPaciente = async (data) => {
        const res = await axios.post('/care/paciente', data);
        if (res.status === 201) {
            // console.log(res.data);
            paciente.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updatePaciente = async (id, data) => {
        const res = await axios.put(`/care/paciente/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            paciente.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updatePacienteAntecedentes = async (id, data) => {
        const res = await axios.put(`/care/paciente/${id}/antecedentes`, data);
        if (res.status === 200) {
            // console.log(res.data);
            paciente.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    // eslint-disable-next-line arrow-body-style
    const getNacimientoFormated = (date) => {
        return DateTime.fromISO(date).toFormat('dd/LLLL/yyyy', { locale: 'co' });
    };

    const getEdad = (date) => {
        const dateObject = DateTime.fromISO(date);
        const age = Math.floor(dateObject.diffNow('years').years * -1);
        if (age === 1) {
            return `${age} año`;
        }
        return `${age} años`;
    };

    return {
        paciente,
        pacientes,
        getPaciente,
        getPacientes,
        getPacientesSelect,
        createPaciente,
        updatePaciente,
        updatePacienteAntecedentes,
        getNacimientoFormated,
        getEdad,
    };
}
