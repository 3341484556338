<template>
    <vue-final-modal :modal-id="modalId" classes="modal-container" content-class="modal-content">
        <div class="modal show d-block">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}&nbsp;</h5>
                        <button class="btn-close" @click="$emit('onClose')"></button>
                    </div>
                    <div
                        class="modal-body p-5 w-100"
                        ref="pdfContainer"
                        style="background-color: #ccc"
                    >
                        <img class="img-fluid" :src="url" />
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
// import pdf from 'pdfvuer';

export default {
    inheritAttrs: false,
    props: {
        url: String,
        modalId: [String, Symbol],
        title: { type: String, default: '' },
    },
    components: {
        VueFinalModal,
    },
};
</script>
<style lang="scss" scoped></style>
