import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const examen = ref(null);
    const examenes = ref([]);

    const getExamen = async (id) => {
        const res = await axios.get(`/care/examen/examen/${id}`);

        if (res.status === 200) {
            examen.value = res.data;
        }
    };

    const getExamenes = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/examen/examen${query}`);

        if (res.status === 200) {
            examenes.value = res.data.items;
        }
    };

    const createExamen = async (data) => {
        const res = await axios.post('/care/examen/examen', data);
        if (res.status === 201) {
            // console.log(res.data);
            examen.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateExamen = async (id, data) => {
        const res = await axios.put(`/care/examen/examen/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            examen.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        examen,
        getExamen,
        examenes,
        getExamenes,
        createExamen,
        updateExamen,
    };
}
