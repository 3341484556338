<template>
    <Datepicker
        :class="{ 'is-invalid': v.date.$errors.length }"
        v-model="date"
        :format="
            (date) => {
                if (date) {
                    const day = `0${date.getDate()}`.slice(-2);
                    const month = `0${date.getMonth() + 1}`.slice(-2);
                    const year = date.getFullYear();

                    return `${year}/${month}/${day}`;
                }
            }
        "
        :enableTimePicker="false"
        autoApply
        v-if="!onlyRead"
    >
        <template #dp-input="{ value }"
            ><input
                :class="`form-control ${size} ${v.date.$errors.length ? 'is-invalid' : ''}`"
                type="text"
                :value="value"
            />
            <div class="invalid-feedback" v-if="v.date.$errors.length">
                {{ v.date.$errors[0].$message }}
            </div></template
        ></Datepicker
    >
    <span class="d-flex align-items-center h-100 ps-1" v-else>{{ formatedDate }}</span>
</template>

<script>
// @update:modelValue="handleDate"
// eslint-disable-next-line object-curly-newline
import { computed } from 'vue';
import { DateTime } from 'luxon';
import Datepicker from '@vuepic/vue-datepicker';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'DateInput',
    components: { Datepicker },
    props: {
        modelValue: { type: String },
        onlyRead: {
            type: Boolean,
            deafult: false,
        },
        size: {
            type: String,
            deafult: '',
        },
    },
    setup(props, { emit }) {
        /** ***** Validator ***** */
        const rules = computed(() => ({
            date: { required },
        }));

        const state = computed(() => ({ date: props.modelValue }));

        const v = useVuelidate(rules, state);

        const date = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                if (value) {
                    const day = `0${value.getDate()}`.slice(-2);
                    const month = `0${value.getMonth() + 1}`.slice(-2);
                    const year = value.getFullYear();

                    const primer = DateTime.fromISO(`${year}-${month}-${day}T00:00:00`).toISO();

                    emit('update:modelValue', primer);
                    emit('onChange', primer);
                } else {
                    emit('update:modelValue', '');
                    emit('onChange', '');
                }
            },
        });

        const formatedDate = computed(() => DateTime.fromISO(date.value).toFormat('yyyy-MM-dd'));

        return { date, formatedDate, v };
    },
};
</script>

<style scoped></style>
