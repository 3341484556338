import axios from '@/services/useAxios';

export default function () {
    const getFiles = async (resultadoId) => {
        const res = await axios.get(`/care/examen/resultado/files/${resultadoId}`);
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const orderFiles = async (resultadoId, files) => {
        const res = await axios.put(`/care/examen/resultado/files/${resultadoId}`, { files });
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const removeFile = async (resultadoId, fileId) => {
        await axios.delete(`/care/examen/resultado/${resultadoId}/file/${fileId}`);
    };

    return { getFiles, orderFiles, removeFile };
}
