<template>
    <vue-final-modal :modal-id="modalId" classes="modal-container" content-class="modal-content">
        <div class="modal show d-block">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}&nbsp;</h5>
                        <button class="btn-close" @click="$emit('onClose')"></button>
                    </div>
                    <div
                        class="modal-body p-5 w-100"
                        ref="pdfContainer"
                        style="background-color: #ccc"
                    >
                        <!-- <pdf class="modal__content pdf" :src="url" /> -->
                        <vue-pdf-embed
                            class="vue-pdf-embed"
                            :disableTextLayer="false"
                            :source="url"
                            ref="pdfEmbed"
                        />
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import VuePdfEmbed from 'vue-pdf-embed';
// import pdf from 'pdfvuer';

export default {
    inheritAttrs: false,
    props: {
        url: String,
        modalId: [String, Symbol],
        title: { type: String, default: '' },
    },
    components: {
        VueFinalModal,
        VuePdfEmbed,
    },
};
</script>
<style lang="scss" scoped>
.pdf {
    max-height: calc(75vh);
    overflow: scroll;
}

::v-deep(.vue-pdf-embed > div) {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
/* ::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
.modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
}
.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
} */
</style>

<style scoped>
/* .dark-mode div::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
} */
</style>
