export default function useCompare() {
    const shallowEqual = (object1, object2) => {
        if (object1 === undefined || object2 === undefined) {
            return false;
        }
        // console.log(object1, object2);
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        // console.log(keys1);
        // console.log(keys2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        // for (const key of keys1)
        const diff = keys1.filter((key) => object1[key] !== object2[key]);

        if (diff.length) console.log(diff);
        return diff.length === 0;
    };

    return { shallowEqual };
}
