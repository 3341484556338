import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const institucion = ref(null);
    const instituciones = ref([]);

    const getInstitucion = async (id) => {
        const res = await axios.get(`/care/institucion/${id}`);

        if (res.status === 200) {
            institucion.value = res.data;
        }
    };

    const getInstituciones = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/institucion${query}`);

        if (res.status === 200) {
            instituciones.value = res.data.items;
        }
    };

    const createInstitucion = async (data) => {
        const res = await axios.post('/care/institucion', data);
        if (res.status === 201) {
            // console.log(res.data);
            institucion.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateInstitucion = async (id, data) => {
        const res = await axios.put(`/care/institucion/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            institucion.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        institucion,
        instituciones,
        getInstitucion,
        getInstituciones,
        createInstitucion,
        updateInstitucion,
    };
}
