import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const resultado = ref(null);

    const createResultado = async (data) => {
        const res = await axios.post('/care/examen/resultado/', data);
        if (res.status === 201) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getResultado = async (id) => {
        const res = await axios.get(`/care/examen/resultado/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getResultadoByKey = async (key) => {
        const res = await axios.get(`/care/examen/resultado/key/${key}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateResultado = async (id, data) => {
        const res = await axios.put(`/care/examen/resultado/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const generateDocument = async (id, templateId, files) => {
        const res = await axios.post(`/care/examen/resultado/render/${id}`, { templateId, files });
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const mergeFiles = async (id, files) => {
        const res = await axios.post(`/care/examen/resultado/merge/${id}`, { files });
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeRender = async (id, renderId) => {
        const res = await axios.delete(`/care/examen/resultado/render/${id}/${renderId}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const createCopy = async (id, renderId) => {
        const res = await axios.post(`/care/examen/resultado/render/${id}/copy/${renderId}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const closeResultado = async (id) => {
        const res = await axios.post(`/care/examen/resultado/close/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const publishDocument = async (id) => {
        const res = await axios.post(`/care/examen/resultado/publish/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const resendDocument = async (id, email) => {
        const body = email ? { email } : {};
        const res = await axios.post(`/care/examen/resultado/resend/${id}`, body);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const gobackDocument = async (id) => {
        const res = await axios.post(`/care/examen/resultado/goback/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const resetRenders = async (id) => {
        const res = await axios.post(`/care/examen/resultado/reset-renders/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            resultado.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };
    return {
        resultado,
        createResultado,
        getResultado,
        getResultadoByKey,
        updateResultado,
        generateDocument,
        mergeFiles,
        closeResultado,
        publishDocument,
        resendDocument,
        gobackDocument,
        removeRender,
        createCopy,
        resetRenders,
    };
}
